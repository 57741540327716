import * as ShipmentsActions from './shipments.actions';
import * as CoreActions from '@common/_store/core.actions';

import {EntityStateModel} from '@common/_models/core.models';
import {BrokerLoadModel} from '../../../../../services/broker/model/brokerLoadModel';
import {BrokerLoadSearchRequest} from '../../../../../services/broker/model/brokerLoadSearchRequest';
import {createEntityAdapter} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {StoredComponentHelperService} from '@common/_services/stored-component.helper.service';

export const shipmentsFeatureKey = 'shipments';

export function sortShipments(a: BrokerLoadModel, b: BrokerLoadModel) {
    return StoredComponentHelperService.sortArrayComparer(a, b, 'created', 'DESC');
}

export const shipmentsAdapter = createEntityAdapter<BrokerLoadModel>({
    sortComparer: sortShipments
});

export const typedListInitialState: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest> = shipmentsAdapter.getInitialState({
    pagination: {},
    listLoading: 'listLoading',
    itemsLoading: []
});

export const initialState: ShipmentsState = {
    CREATED: {...typedListInitialState},
    IN_PROGRESS: {...typedListInitialState},
    CLOSED: {...typedListInitialState}
};

export interface ShipmentsState {
    CREATED: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest>,
    IN_PROGRESS: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest>,
    CLOSED: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest>,
}

export const shipmentsReducer = createReducer(
    initialState,

    on(ShipmentsActions.LoadList, (state, action) => {
        return {
            ...state,
            [action.payload.pageType]: {
                ...state[action.payload.pageType],
                listLoading: action.payload.loading
            }
        };
    }),

    on(ShipmentsActions.LoadListSuccess, (state, action) => {
        if (action.payload.clearState) {
            return {
                ...state,
                [action.payload.pageType]: shipmentsAdapter.setAll(action.payload.items, {
                    ...state[action.payload.pageType],
                    pagination: action.payload.pagination,
                    listLoading: undefined,
                })
            };
        }

        return {
            ...state,
            [action.payload.pageType]: shipmentsAdapter.upsertMany(action.payload.items, {
                ...state[action.payload.pageType],
                pagination: action.payload.pagination,
                listLoading: undefined,
            })
        };
    }),

    on(ShipmentsActions.LoadListFailure, (state, action) => {
        return {
            ...state,
            [action.payload.pageType]: {
                ...state[action.payload.pageType],
                listLoading: undefined,
            }
        };
    }),

    on(ShipmentsActions.SetFilter, (state, action) => {
        return {
            ...state,
            [action.payload.pageType]: {
                ...state[action.payload.pageType],
                filter: {
                    ...action.payload.filter
                },
            }
        };
    }),

    on(ShipmentsActions.SetPagination, (state, action) => {
        return {
            ...state,
            [action.payload.pageType]: {
                ...state[action.payload.pageType],
                pagination: {
                    ...state[action.payload.pageType].pagination,
                    number: action.payload.next ? StoredComponentHelperService.setNextPage(state[action.payload.pageType].pagination, state[action.payload.pageType].ids.length) : 0
                },
            }
        };
    }),

    on(ShipmentsActions.SetListLoading, (state, action) => {
        return {
            ...state,
            [action.payload.pageType]: {
                ...state[action.payload.pageType],
                listLoading: action.payload.loading
            }
        };
    }),

    on(ShipmentsActions.SetInitialState, (state, action) => {
        return {
            ...state,
            [action.payload.pageType]: {
                ...state[action.payload.pageType],
                ...typedListInitialState
            }
        };
    }),

    on(ShipmentsActions.DeleteItem, (state, action) => ({
        ...state,
        [action.payload.pageType]: {
            ...state[action.payload.pageType],
            itemsLoading: StoredComponentHelperService.updateItemLoading(state[action.payload.pageType].itemsLoading, action.payload.item.id!, true)
        }
    })),

    on(ShipmentsActions.DeleteItemSuccess, (state, action) => {
        return ({
            ...state,
            [action.payload.pageType]: shipmentsAdapter.removeOne(action.payload.item.id!, {
                ...state[action.payload.pageType],
                itemsLoading: StoredComponentHelperService.updateItemLoading(state[action.payload.pageType].itemsLoading, action.payload.item.id!, false),
                pagination: {
                    ...state[action.payload.pageType].pagination,
                    totalElements: StoredComponentHelperService.setTotalElements(action.payload.item.id!, state[action.payload.pageType].pagination, state[action.payload.pageType]?.ids, true)
                }
            })
        });
    }),

    on(ShipmentsActions.SetItemsLoading, (state, action) => ({
        ...state,
        [action.payload.pageType]: {
            ...state[action.payload.pageType],
            itemsLoading: StoredComponentHelperService.updateItemLoading(state[action.payload.pageType].itemsLoading, action.payload.id!, action.payload.isStart)
        }
    })),

    on(ShipmentsActions.UpdateItemSuccess, (state, action) => {
        const prevStatus = action.payload.pageType;
        const newStatus = action.payload.item.status!;
        const savedItemAvailableByFilter = isAvailableByFilter(state[newStatus].filter, action.payload.item);

        if (prevStatus === newStatus && savedItemAvailableByFilter) {
            return {
                ...state,
                [action.payload.pageType]: shipmentsAdapter.setOne(action.payload.item, {
                    ...state[action.payload.pageType],
                    itemsLoading: StoredComponentHelperService.updateItemLoading(state[action.payload.pageType].itemsLoading, action.payload.item.id!, false),
                    pagination: {
                        ...state[action.payload.pageType].pagination,
                        totalElements: StoredComponentHelperService.setTotalElements(action.payload.item.id!, state[action.payload.pageType].pagination, state[action.payload.pageType]?.ids)
                    }
                })
            };
        } else {
            return ({
                ...state,
                [action.payload.pageType]: shipmentsAdapter.removeOne(action.payload.item.id!, {
                    ...state[action.payload.pageType],
                    itemsLoading: StoredComponentHelperService.updateItemLoading(state[action.payload.pageType].itemsLoading, action.payload.item.id!, false),
                    pagination: {
                        ...state[action.payload.pageType].pagination,
                        totalElements: StoredComponentHelperService.setTotalElements(action.payload.item.id!, state[action.payload.pageType].pagination, state[action.payload.pageType]?.ids, true)
                    }
                })
            });
        }
    }),

    on(CoreActions.LogOutSuccess, () => ({...initialState}))
);

const isAvailableByFilter = (filter: BrokerLoadSearchRequest | undefined, item: BrokerLoadModel): boolean => {
    if (!filter) {
        return true;
    }

    // TODO: Vit: filter.keyWord

    if (filter.from && !item.stops?.pickUp?.place?.includes(filter.from)
        && !item.stops?.pickUp?.street?.includes(filter.from)
        && !item.stops?.pickUp?.state?.includes(filter.from)) {
        return false;
    }

    return !(filter.to && !item.stops?.pickUp?.place?.includes(filter.to)
        && !item.stops?.pickUp?.street?.includes(filter.to)
        && !item.stops?.pickUp?.state?.includes(filter.to));
};
