import {LoadingType, PaginationModel} from '@common/_models/core.models';

export class StoredComponentHelperService {
    static cleanPaginationRequest(pagination?: PaginationModel): PaginationModel {
        return {page: pagination?.number ? pagination?.number : 0, size: pagination?.size ? pagination?.size : 20};
    }

    static cleanPaginationResponse(pagination?: PaginationModel): PaginationModel {
        return {
            first: pagination?.first as boolean,
            last: pagination?.last as boolean,
            number: pagination?.number as number,
            numberOfElements: pagination?.numberOfElements as number,
            size: pagination?.size as number,
            totalElements: pagination?.totalElements as number,
            totalPages: pagination?.totalPages as number
        };
    }

    static setNextPage(pagination?: PaginationModel, elementsLength?: number): number {
        const pageSize: number = pagination?.size ? pagination.size : 0;
        const storedEntitiesCount = elementsLength as number;
        return (storedEntitiesCount - storedEntitiesCount % pageSize) / pageSize;
    }

    static setTotalElements(elementId: string, pagination?: PaginationModel, ids?: string[] | number[], isDelete?: boolean): number {
        let totalElements: number = pagination?.totalElements ? pagination.totalElements : 0;

        if (isDelete && totalElements && ids && ids.some(el => el == elementId)) {
            totalElements -= 1;
        }

        if (!isDelete && (!ids || !ids?.some(el => el == elementId))) {
            totalElements += 1;
        }

        return totalElements;
    }

    static updateListLoading(actionPayload: { loading: LoadingType, clearState: boolean }, ids?: string[] | number[]): LoadingType {
        return actionPayload.loading == 'listLoading' && ids?.length && !actionPayload.clearState ? undefined : actionPayload.loading;
    }

    static updateItemLoading(loadingItems: string [] | undefined, id: string, isStart: boolean): string[] {
        const items = loadingItems ? [...loadingItems] : [];
        if (isStart) {
            items.push(id);
        } else {
            return items.filter(el => el != id);
        }

        return items;
    }

    static sortArrayComparer(a: any, b: any, prop: string, direction: 'ASC' | 'DESC' = 'ASC') {
        const valA = a[prop] instanceof Date ? a[prop].getTime() : a[prop];
        const valB = b[prop] instanceof Date ? b[prop].getTime() : b[prop];

        if (valA < valB) {
            return direction === 'ASC' ? -1 : 1;
        } else if (valA > valB) {
            return direction === 'ASC' ? 1 : -1;
        } else {
            return 0;
        }
    }


    static sortArray(array: any[], field: string, direction?: 'ASC' | 'DESC', useLocale?: boolean): any[] {
        if (!Array.isArray(array) || !field) {
            return array;
        }

        if (!direction || direction == 'ASC') {
            useLocale
                ? array.sort((a: any, b: any) => {
                    return a[field].localeCompare(b[field]);
                })
                : array.sort((a: any, b: any) => {
                    if (a[field] < b[field]) {
                        return -1;
                    } else if (a[field] > b[field]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
        }

        if (direction == 'DESC') {
            useLocale
                ? array.sort((a: any, b: any) => {
                    return b[field].localeCompare(a[field]);
                })
                : array.sort((a: any, b: any) => {
                    if (a[field] > b[field]) {
                        return -1;
                    } else if (a[field] < b[field]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
        }

        return array;
    }
}

