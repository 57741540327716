import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {CustomerChatModel} from '../../../../services/chat/model/customerChatModel';
import {environment} from '../../../environments/environment';
import {PaginationModel} from '@common/_models/core.models';
import {SliceCustomerChatMessageModel} from '../../../../services/chat/model/sliceCustomerChatMessageModel';
import {CustomerChatMessageModel} from '../../../../services/chat/model/customerChatMessageModel';

@Injectable({
    providedIn: 'root'
})

export class MessengerService {
    private baseUrl = environment.baseUrl + '/chat';

    constructor(
        private http: HttpClient,
    ) {
    }

    getAllConversations(): Observable<CustomerChatModel[]> {
        const url = `${this.baseUrl}/v1/customer/chat/list/broker/load`;
        return this.http.get<CustomerChatModel[]>(url);
    }

    /** Customer Load Chat */
    getConversation(loadId: string): Observable<CustomerChatModel> {
        const url = `${this.baseUrl}/v1/customer/chat/get/${loadId}`;
        return this.http.get<CustomerChatModel>(url);
    }

    getConversationMessages(loadId: string, pagination: PaginationModel): Observable<SliceCustomerChatMessageModel> {
        const url = `${this.baseUrl}/v1/customer/message/list/${loadId}?page=${pagination.number}&size=${pagination.size}`;
        return this.http.get<SliceCustomerChatMessageModel>(url);
    }

    sendConversationMessage(loadId: string, request: CustomerChatMessageModel): Observable<CustomerChatMessageModel> {
        const url = `${this.baseUrl}/v1/customer/message/${loadId}`;
        return this.http.post<CustomerChatMessageModel>(url, request);
    }

    readConversation(loadId: string): Observable<CustomerChatModel> {
        const url = `${this.baseUrl}/v1/customer/chat/read/${loadId}`;
        return this.http.get<CustomerChatModel>(url);
    }

    deleteConversationMessage(messageId: string): Observable<void> {
        const url = `${this.baseUrl}/v1/customer/message/delete/${messageId}`;
        return this.http.delete<void>(url);
    }
}
