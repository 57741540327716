import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BrokerLoadSearchRequest} from '../../../../../services/broker/model/brokerLoadSearchRequest';
import {Observable} from 'rxjs';
import {BrokerLoadModel} from '../../../../../services/broker/model/brokerLoadModel';
import {PageableResponse, PaginationModel} from '@common/_models/core.models';
import {StoredComponentHelperService} from '@common/_services/stored-component.helper.service';
import {environment} from '../../../../environments/environment';
import {PostCodeDto} from '../../../../../services/locations/model/postCodeDto';
import {LoadPlaceRequest} from '../../../../../services/loads/model/loadPlaceRequest';
import {LoadPlaceResponse} from '../../../../../services/loads/model/loadPlaceResponse';
import {BrokerLoadAutoRepostModel} from '../../../../../services/broker/model/brokerLoadAutoRepostModel';
import {BrokerLoadCarrierRequest} from '../../../../../services/broker/model/brokerLoadCarrierRequest';

@Injectable({
    providedIn: 'root'
})
export class ShipmentsHTTPService {
    private featureUrl = `${environment.baseUrl}/broker`;

    constructor(
        private http: HttpClient
    ) {
    }

    getList(request?: BrokerLoadSearchRequest, pagination?: PaginationModel): Observable<PageableResponse<BrokerLoadModel>> {
        return this.http.post<PageableResponse<BrokerLoadModel>>(`${this.featureUrl}/load/search`, request, {
            params: {
                ...StoredComponentHelperService.cleanPaginationRequest(pagination)
            }
        });
    }

    getItem(brokerLoadId: string): Observable<BrokerLoadModel> {
        return this.http.get<BrokerLoadModel>(`${this.featureUrl}/load/${brokerLoadId}`);
    }

    saveItem(request: BrokerLoadModel, post?: boolean): Observable<BrokerLoadModel> {
        if (request.id) {
            return this.http.put<BrokerLoadModel>(`${this.featureUrl}/load`, request, {
                params: {...post && {post: post}}
            });
        } else {
            return this.http.post<BrokerLoadModel>(`${this.featureUrl}/load`, request, {
                params: {...post && {post: post}}
            });
        }
    }

    deleteItem(brokerLoadId: string): Observable<void> {
        return this.http.delete<void>(`${this.featureUrl}/load/${brokerLoadId}`);
    }

    getStateAndCityByZip(query: string): Observable<PostCodeDto> {
        const url = `${environment.baseUrl}/locations/zip/info/${query}`;
        return this.http.post<PostCodeDto>(url, query);
    }

    getRouteCalculation(request: LoadPlaceRequest[]): Observable<LoadPlaceResponse[]> {
        const url = `${environment.baseUrl}/loads/location/distances`;
        return this.http.post<LoadPlaceResponse[]>(url, request);
    }

    requestQuote(brokerLoadId: string): Observable<BrokerLoadModel> {
        return this.http.get<BrokerLoadModel>(`${this.featureUrl}/load/quote/request/${brokerLoadId}`);
    }

    cancelQuote(brokerLoadId: string): Observable<BrokerLoadModel> {
        return this.http.get<BrokerLoadModel>(`${this.featureUrl}/load/quote/stop/${brokerLoadId}`);
    }

    setAutoRepost(brokerLoadId: string, request: BrokerLoadAutoRepostModel): Observable<BrokerLoadModel> {
        return this.http.put<BrokerLoadModel>(`${this.featureUrl}/load/autoRepost/${brokerLoadId}`, request);
    }

    editCarrier(brokerLoadId: string, request: BrokerLoadCarrierRequest): Observable<BrokerLoadModel> {
        return this.http.put<BrokerLoadModel>(`${this.featureUrl}/load/carrier/${brokerLoadId}`, request);
    }

    changeStatus(brokerLoadId: string, status: BrokerLoadModel.StatusEnum): Observable<BrokerLoadModel> {
        return this.http.get<BrokerLoadModel>(`${this.featureUrl}/load/status/${brokerLoadId}/${status}`);
    }
}
