import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {BrokerLoadModel} from '../../../../../services/broker/model/brokerLoadModel';
import {LoadingType, PaginationModel} from '@common/_models/core.models';
import {BrokerLoadSearchRequest} from '../../../../../services/broker/model/brokerLoadSearchRequest';

export const LoadList = createAction(
    '[Shipments] Load List',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, loading?: LoadingType, clearState?: boolean } }>()
);

export const LoadListSuccess = createAction(
    '[Shipments] Load List Success',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, items: BrokerLoadModel[], pagination: PaginationModel, clearState?: boolean } }>()
);

export const LoadListFailure = createAction(
    '[Shipments] Load List Failure',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, error: HttpErrorResponse } }>()
);

export const SetFilter = createAction(
    '[Shipments] Set Filter',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, filter: BrokerLoadSearchRequest } }>()
);

export const SetPagination = createAction(
    '[Shipments] Set Pagination',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, next?: boolean } }>()
);

export const SetListLoading = createAction(
    '[Shipments] Set List Loading',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, loading: LoadingType } }>()
);

export const SetItemsLoading = createAction(
    '[Shipments] Set Items Loading',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, id: string, isStart: boolean } }>()
);

export const SetInitialState = createAction(
    '[Shipments] Set Initial State',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum } }>()
);

export const DeleteItem = createAction(
    '[Shipments] Delete Item',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, item: BrokerLoadModel } }>()
);

export const DeleteItemSuccess = createAction(
    '[Shipments] Delete Item Success',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, item: BrokerLoadModel } }>()
);

export const UpdateItemSuccess = createAction(
    '[Shipments] Update Item Success',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, item: BrokerLoadModel } }>()
);

export const RequestQuote = createAction(
    '[Shipments] Request Quote',
    props<{ payload: { pageType: BrokerLoadModel.StatusEnum, item: BrokerLoadModel } }>()
);
