import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {KeyWordDto} from '../../../../../services/dictionaries/model/keyWordDto';
import {CustomerResponse} from '../../../../../services/dictionaries/model/customerResponse';
import {LoadingType} from '@common/_models/core.models';

export const LoadList = createAction(
    '[Users] Load List',
    props<{ payload: { loading?: LoadingType, clearState?: boolean } }>()
);

export const LoadListSuccess = createAction(
    '[Users] Load List Success',
    props<{ payload: { items: CustomerResponse[], clearState?: boolean } }>()
);

export const LoadListFailure = createAction(
    '[Users] Load List Failure',
    props<{ payload: { error: HttpErrorResponse } }>()
);

export const SetFilter = createAction(
    '[Users] Set Filter',
    props<{ payload: { filter?: KeyWordDto } }>()
);

export const SetListLoading = createAction(
    '[Users] Set List Loading',
    props<{ payload: { loading: LoadingType } }>()
);

export const SetItemsLoading = createAction(
    '[Users] Set Items Loading',
    props<{ payload: { id: string, isStart: boolean } }>()
);

export const SetInitialState = createAction(
    '[Users] Set Initial State'
);

export const DeleteItem = createAction(
    '[Users] Delete Item',
    props<{ payload: { item: CustomerResponse } }>()
);

export const DeleteItemSuccess = createAction(
    '[Users] Delete Item Success',
    props<{ payload: { item: CustomerResponse } }>()
);

export const DeleteItemFailure = createAction(
    '[Users] Delete Item Failure',
    props<{ payload: { item: CustomerResponse, error: HttpErrorResponse } }>()
);

export const UpdateItemSuccess = createAction(
    '[Users] Update Item Success',
    props<{ payload: { item: CustomerResponse } }>()
);
