import * as UsersActions from './users.actions';
import * as CoreActions from '@common/_store/core.actions';
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {CustomerResponse} from '../../../../../services/dictionaries/model/customerResponse';
import {EntityStateModel, LoadingType, PaginationModel} from '@common/_models/core.models';
import {KeyWordDto} from '../../../../../services/dictionaries/model/keyWordDto';
import {StoredComponentHelperService} from '@common/_services/stored-component.helper.service';

export const usersFeatureKey = "users";

export function sortByRole(a: CustomerResponse, b: CustomerResponse) {
    if (a.role! > b.role!) {
        return 1;
    } else if (a.role! < b.role!) {
        return -1;
    } else {
        return StoredComponentHelperService.sortArrayComparer(a, b, 'firstName', 'ASC');
    }
}

export const usersAdapter: EntityAdapter<CustomerResponse> = createEntityAdapter<CustomerResponse>({
    sortComparer: sortByRole
});

export interface UsersState extends EntityStateModel<CustomerResponse, KeyWordDto> {
    pagination: PaginationModel,
    listLoading: LoadingType,
    itemsLoading: string[]
}

export const initialState: UsersState = usersAdapter.getInitialState({
    pagination: {},
    listLoading: 'listLoading',
    itemsLoading: []
});

export const usersReducer = createReducer(
    initialState,

    on(UsersActions.LoadList, (state, action) => {
        return {
            ...state,
            listLoading: action.payload.loading,
        };
    }),

    on(UsersActions.LoadListSuccess, (state, action) => {
        if (action.payload.clearState) {
            return usersAdapter.setAll(action.payload.items, {
                ...state,
                listLoading: undefined,
            });
        }

        return usersAdapter.upsertMany(action.payload.items, {
            ...state,
            listLoading: undefined,
        });
    }),

    on(UsersActions.LoadListFailure, (state) => {
        return {
            ...state,
            listLoading: undefined,
        };
    }),

    on(UsersActions.SetFilter, (state, action) => {
        return {
            ...state,
            filter: {
                ...action.payload.filter
            },
        };
    }),

    on(UsersActions.SetListLoading, (state, action) => {
        return {
            ...state,
            listLoading: action.payload.loading
        };
    }),

    on(UsersActions.SetInitialState, () => {
        return {
            ...initialState
        };
    }),

    on(UsersActions.DeleteItem, (state, action) => ({
        ...state,
        itemsLoading: StoredComponentHelperService.updateItemLoading(state.itemsLoading, action.payload.item.id!, true)
    })),

    on(UsersActions.DeleteItemSuccess, (state, action) => {
        return usersAdapter.removeOne(action.payload.item.id!, {
            ...state,
            itemsLoading: StoredComponentHelperService.updateItemLoading(state.itemsLoading, action.payload.item.id!, false),
            pagination: {
                ...state.pagination,
                totalElements: StoredComponentHelperService.setTotalElements(action.payload.item.id!, state.pagination, state?.ids, true)
            }
        });
    }),

    on(UsersActions.DeleteItemFailure, (state, action) => ({
        ...state,
        itemsLoading: StoredComponentHelperService.updateItemLoading(state.itemsLoading, action.payload.item.id!, false)
    })),

    on(UsersActions.UpdateItemSuccess, (state, action) => {
        const savedItemAvailableByFilter = isAvailableByFilter(state.filter, action.payload.item);

        if (savedItemAvailableByFilter) {
            return usersAdapter.setOne(action.payload.item, {
                ...state,
                itemsLoading: StoredComponentHelperService.updateItemLoading(state.itemsLoading, action.payload.item.id!, false),
                pagination: {
                    ...state.pagination,
                    totalElements: StoredComponentHelperService.setTotalElements(action.payload.item.id!, state.pagination, state?.ids)
                }
            });
        }

        return usersAdapter.removeOne(action.payload.item.id!, {
            ...state,
            itemsLoading: StoredComponentHelperService.updateItemLoading(state.itemsLoading, action.payload.item.id!, false),
            pagination: {
                ...state.pagination,
                totalElements: StoredComponentHelperService.setTotalElements(action.payload.item.id!, state.pagination, state?.ids)
            }
        });
    }),

    on(CoreActions.LogOutSuccess, () => ({...initialState}))
);

export const {selectAll: selectUsersAll} = usersAdapter.getSelectors();

const isAvailableByFilter = (filter: KeyWordDto | undefined, item: CustomerResponse): boolean => {
    if (!filter?.keyWord?.trim()) {
        return true;
    }

    const fullName = item.firstName + " " + item.lastName;
    return fullName.includes(filter?.keyWord?.trim());
};
