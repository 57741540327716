import {createAction, props} from "@ngrx/store";
import {HttpErrorResponse} from "@angular/common/http";
import {CustomerChatModel} from '../../../../../services/chat/model/customerChatModel';

export const LoadConversations = createAction(
    '[Conversation] Load Customer Chats'
);

export const LoadConversationsSuccess = createAction(
    '[Conversation] Load Customer Chats Success',
    props<{ payload: CustomerChatModel[] }>()
);

export const LoadConversationsFailure = createAction(
    '[Conversation] Load Customer Chats Failure',
    props<{ payload: HttpErrorResponse }>()
);

export const MarkChatAsRead = createAction(
    '[Conversation] Mark Chat As Read',
    props<{ payload: string }>()
);

export const MarkChatAsReadFailure = createAction(
    '[Conversation] Mark Chat As Read Failure',
    props<{ payload: HttpErrorResponse }>()
);

export const UpdateChatSuccess = createAction(
    '[Conversation] Update Chat Success',
    props<{ payload: CustomerChatModel }>()
);

export const DeleteChatSuccess = createAction(
    '[Conversation] Delete Chat Success',
    props<{ payload: string }>()
);
