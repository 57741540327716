import * as ConversationActions from '@conversation/_store/conversation.actions';

import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createReducer, on} from "@ngrx/store";
import {CustomerChatModel} from '../../../../../services/chat/model/customerChatModel';

export const conversationFeatureKey = 'conversation';

export function sortByLastMessageTime(a: CustomerChatModel, b: CustomerChatModel) {
    if (a.lastMessage && b.lastMessage && a.lastMessage!.created! < b.lastMessage!.created!) {
        return 1;
    } else if (a.lastMessage && b.lastMessage && a.lastMessage!.created! > b.lastMessage!.created!) {
        return -1;
    } else {
        return 1;
    }
}

export const conversationAdapter: EntityAdapter<CustomerChatModel> = createEntityAdapter<CustomerChatModel>({
    sortComparer: sortByLastMessageTime,
    selectId: (chat: CustomerChatModel) => chat.load!.id as string
});

export interface ConversationState extends EntityState<CustomerChatModel> {
    loading: boolean;
}

export const initialState: ConversationState = conversationAdapter.getInitialState({
    loading: false
});

export const conversationReducer = createReducer(
    initialState,

    on(ConversationActions.LoadConversations, state => ({...state, loading: true})),
    on(ConversationActions.LoadConversationsSuccess, (state, action) => {
        return conversationAdapter.setAll(action.payload!, {
            ...state,
            loading: false
        });
    }),
    on(ConversationActions.LoadConversationsFailure, state => ({...state, loading: false})),
    on(ConversationActions.UpdateChatSuccess, (state, action) => {
        return conversationAdapter.upsertOne(action.payload, {
            ...state
        });
    }),
    on(ConversationActions.DeleteChatSuccess, (state, action) => {
        return conversationAdapter.removeOne(action.payload, {
            ...state
        });
    })
);
