import {shipmentsAdapter, shipmentsFeatureKey, ShipmentsState} from './shipments.reducer';
import {createSelector} from '@ngrx/store';
import {EntityStateModel} from '@common/_models/core.models';
import {BrokerLoadModel} from '../../../../../services/broker/model/brokerLoadModel';
import {BrokerLoadSearchRequest} from '../../../../../services/broker/model/brokerLoadSearchRequest';

export const getShipmentsState = (state: any) => state[shipmentsFeatureKey];

export const getShipmentsTypeState = (pageType: BrokerLoadModel.StatusEnum) => createSelector(
    getShipmentsState,
    (state: ShipmentsState) => state[pageType],
);

export const selectList = (pageType: BrokerLoadModel.StatusEnum) => createSelector(
    getShipmentsTypeState(pageType),
    shipmentsAdapter.getSelectors().selectAll
);

export const selectPagination = (pageType: BrokerLoadModel.StatusEnum) => createSelector(
    getShipmentsTypeState(pageType),
    (state: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest>) => state.pagination
);

export const selectFilter = (pageType: BrokerLoadModel.StatusEnum) => createSelector(
    getShipmentsTypeState(pageType),
    (state: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest>) => state.filter
);

export const selectListLoading = (pageType: BrokerLoadModel.StatusEnum) => createSelector(
    getShipmentsTypeState(pageType),
    (state: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest>) => state.listLoading
);

export const selectItemsLoading = (pageType: BrokerLoadModel.StatusEnum) => createSelector(
    getShipmentsTypeState(pageType),
    (state: EntityStateModel<BrokerLoadModel, BrokerLoadSearchRequest>) => state.itemsLoading
);
